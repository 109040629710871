import { FC, useState } from "react";
import classNames from "classnames";
import { compact } from "lodash";

import useAccessType from "@hooks/use-access-type";
import useMemberFilterOptions from "@hooks/use-member-filter-options";

import Listbox, { ListboxOption, useSimpleSearchProps } from "./Listbox";

interface MemberFilterProps {
  defaultValue?: string;
  setFilters: (filters: Record<string, string>) => void;
  listboxClassNames?: string;
}

const MemberFilter: FC<MemberFilterProps> = ({
  defaultValue,
  setFilters,
  listboxClassNames,
}) => {
  const { hasFullAccess } = useAccessType();
  const [memberId, setMemberId] = useState<string>(
    defaultValue ?? "all-members"
  );

  const { options: unformattedMembers } = useMemberFilterOptions();
  const memberOptions = compact(
    unformattedMembers?.map(({ value, text }) => {
      if (value === "all")
        return {
          title: "All members",
          value: "all-members",
        };
      return {
        title: text,
        value,
      };
    })
  );
  const { searchProps, filteredOptions } = useSimpleSearchProps(
    memberOptions,
    "Search members..."
  );

  if (!hasFullAccess) return null;

  const formatOptionLabel = (member) => {
    return (
      <div className="flex items-center">
        <p className="font-medium text-sm text-black-ink text-ellipsis overflow-hidden">
          {member.title}
        </p>
      </div>
    );
  };

  const handleMemberChange = (val) => {
    setMemberId(val);
    const value = val === "all-members" ? undefined : val;
    setFilters({
      memberId: value,
    });
  };
  const member = memberOptions.find((member) => member.value === memberId);

  return (
    <Listbox
      onChange={handleMemberChange}
      placeholder={member ? formatOptionLabel(member) : "Select a member"}
      value={memberId}
      className="!w-60 !space-y-0"
      customListboxClassNames={classNames(
        "!bg-grey-950 !border-none !h-[30px]",
        listboxClassNames
      )}
      searchProps={
        searchProps
          ? { ...searchProps, searchInputClassNames: "bg-white" }
          : undefined
      }
    >
      {filteredOptions?.map((member) => (
        <ListboxOption
          key={member.value}
          primaryText={member.title}
          value={member.value}
          className="bg-white hover:bg-action-900 hover:cursor-pointer"
        >
          {formatOptionLabel(member)}
        </ListboxOption>
      ))}
    </Listbox>
  );
};

export default MemberFilter;
